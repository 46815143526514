import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { QuoteState, QuoteMutations, StateKeys, IQuote } from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getQuoteDefaults } from "@/helpers/defaultObjectGenerators";
import { omit } from "lodash";
import { defaultState } from "./state";
import { getBuildingAmountPercentage } from "@/forms/utils/index";

export const mutations: DefineMutations<QuoteMutations, QuoteState> = {
  [types.SET_STATE]: GenericSetState<QuoteState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<QuoteState>(),
  [types.SET_EDIT]: GenericSetEdit<QuoteState, string | IQuote>(
    "quotes",
    getQuoteDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<QuoteState, keyof IQuote>(),
  [types.SET_ITEM]: GenericSetItem<QuoteState, IQuote>(
    "quotes",
    getQuoteDefaults
  ), //TODO: WRITE A getXDefaultsFunction and pass it here in place of the anonymous function
  [types.DELETE_ITEM]: GenericDeleteItem<QuoteState>("quotes", "_id"),
  [types.UPDATE_ENDORSEMENTS](state: QuoteState, payload: any) {
    if (payload.action === "add") {
      state.editing.endorsements.push(
        omit(payload, ["action", "createdOn", "modifiedOn", "data"])
      );
    } else if (payload.action === "remove") {
      const index = state.editing.endorsements.findIndex((e: any) => {
        return e.formNumber === payload.formNumber;
      });
      state.editing.endorsements.splice(index, 1);
    }
  },
  [types.UPDATE_ENDORSEMENT_FIELD](state: QuoteState, payload: any) {
    const found = state.editing.endorsements.find((e: any) => {
      return e.formNumber === payload.formNumber;
    });
    if (!found.values) {
      found.values = [];
      found.values.push(payload.value);
    }
    const val = found.values.find((v: any) => {
      return v.key == payload.value.key;
    });
    if (val) {
      val.value = payload.value.value;
    } else {
      found.values.push(payload.value);
    }
  },
  [types.RESET_MANDATORY_ENDORSEMENTS](
    state: QuoteState,
    mandatoryEndorsements: any[]
  ) {
    state.editing.endorsements = state.editing.endorsements.filter(
      (endorsement: any) => endorsement.formUsage === "Optional"
    );
    for (const mandatoryEndorsement of mandatoryEndorsements) {
      state.editing.endorsements.push(
        omit(mandatoryEndorsement, [
          "action",
          "createdOn",
          "modifiedOn",
          "data"
        ])
      );
    }
  },
  [types.SET_EDIT_OVERRIDE_FIELD](
    state: QuoteState,
    { formType, companyNumber, value, contentsAmount, otherStructures }
  ) {
    const { amountDWPCompanyOverrides } = state.editing;

    // remove from array if it exists
    state.editing.amountDWPCompanyOverrides = amountDWPCompanyOverrides.filter(
      (e: any) =>
        !(e.companyNumber === companyNumber && e.formType === formType)
    );
    // add to array if different
    // if (amountDWP.toString() !== value.toString()) {
    state.editing.amountDWPCompanyOverrides.push({
      formType,
      companyNumber,
      amount: value,
      contentsAmount: contentsAmount,
      otherStructures: otherStructures
    });
    // }
  },
  [types.SET_EDIT_OVERRIDE_FIELD_OTHERS](
    state: QuoteState,
    { formType, companyNumber, value, field }
  ) {
    const { amountDWPCompanyOverrides } = state.editing;
    const match = amountDWPCompanyOverrides.find(
      (e: any) => e.companyNumber === companyNumber && e.formType === formType
    );

    if (match) match[field] = value;
  },
  [types.SET_CONTENTS_AMOUNTS_COMPANY_OVERRIDES](
    state: QuoteState,
    { endorsementToCheck, action }
  ) {
    const { amountDWPCompanyOverrides } = state.editing;

    const overridesToUpdate = amountDWPCompanyOverrides.filter(
      (override: any) => {
        if (endorsementToCheck === "HO-101") {
          return override.formType === "HB" && override.companyNumber === 20;
        } else if (endorsementToCheck === "HO-101B") {
          return (
            override.formType === "HB" &&
            [26, 27, 28, 29, 30].includes(override.companyNumber)
          );
        } else if (endorsementToCheck === "HO-401") {
          return (
            override.formType === "AP" &&
            [26, 99].includes(override.companyNumber)
          );
        }
      }
    );

    for (const override of overridesToUpdate) {
      endorsementToCheck = action === "remove" ? "" : endorsementToCheck;
      const percentageOfBuildingAmount = getBuildingAmountPercentage(
        endorsementToCheck
      );
      override.contentsAmount = Math.round(
        percentageOfBuildingAmount * override.amount
      );
    }
  },
  [types.EDIT_DISCOUNT_FIELD](state: QuoteState, { key, value }) {
    state.editing.discounts[key] = value;
  },
  [types.EDIT_UNDERWRITING_RESPONSE_FIELD](state: QuoteState, { key, value }) {
    state.editing.underwritingResponses = Object.assign(
      {},
      state.editing.underwritingResponses,
      { [key]: value }
    );
  },
  [types.CLEAR_QUOTE_STORE]: GenericClearStore<QuoteState>(defaultState()),
  [types.ADD_RATING_ID](state: QuoteState, ratingId: string) {
    if (!state.socketRatingIds.includes(ratingId)) {
      state.socketRatingIds.push(ratingId);
    }
  },
  [types.REMOVE_RATING_IDS](state: QuoteState) {
    state.socketRatingIds = [];
  }
};
