const title = "Personal Liability Policy Applications";
export default [
  {
    path: "/personal-liability-policies",
    component: () =>
      import(
        /* webpackChunkName: "PersonalLiabilityList" */ "@/views/admin/personalLiabilityPolicy/PersonalLiabilityList/PersonalLiabilityList.vue"
      ),
    meta: {
      pageTitle: title,
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ],
      metaTags: {
        title
      }
    }
  },
  {
    path: "/personal-liability-policies/:id",
    component: () =>
      import(
        /* webpackChunkName: "CreateNewPersonalLiabilityList" */ "@/views/admin/personalLiabilityPolicy/SinglePersonalLiabilityPolicy/Edit.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/personal-liability-policies",
          title
        }
      ],
      metaTags: {
        title
      },
      pageTitle: "View Personal Liability Policy Application",
      requireAuth: true,
      allowedRoles: [
        "admin",
        "agent",
        "agencyAdmin",
        "subAdmin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ]
    }
  },
  {
    path: "/new/personal-liability-policies",
    component: () =>
      import(
        /* webpackChunkName: "CreateNewPersonalLiabilityList" */ "@/views/shared/personalLiabilityPolicy/Create.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/personal-liability-policies",
          title
        }
      ],
      metaTags: {
        title
      },
      pageTitle: "Create Personal Liability Policy Application",
      requireAuth: true,
      allowedRoles: "anyone"
    }
  },
  {
    path: "/edit/personal-liability-policies/:id",
    component: () =>
      import(
        /* webpackChunkName: "CreateNewPersonalLiabilityList" */ "@/views/shared/personalLiabilityPolicy/Edit.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/personal-liability-policies",
          title
        }
      ],
      metaTags: {
        title
      },
      pageTitle: "Edit Personal Liability Policy Application",
      requireAuth: true,
      allowedRoles: "anyone"
    }
  },
  {
    path: "/personal-liability-policies/submission-success",
    component: () =>
      import(
        /* webpackChunkName: "CreateNewPersonalLiabilityList" */ "@/views/shared/personalLiabilityPolicy/SubmissionSuccess.vue"
      ),
    meta: {
      pageTitle: title,
      metaTags: {
        title
      },
      requireAuth: true,
      allowedRoles: "anyone"
    }
  }
];
