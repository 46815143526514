'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'brain';
var width = 544;
var height = 512;
var ligatures = [];
var unicode = 'f5dc';
var svgPathData = 'M509.6 230.3c17.5-54.5-20.1-93.2-41.1-105.3 2.7-37.7-25.9-74.3-66.7-79.4C390 18.2 362.8 0 332.2 0 307.6 0 286 11.9 272 29.9 258 11.9 236.4 0 211.8 0c-30.6 0-57.7 18.2-69.7 45.6-40.8 5.1-69.3 41.7-66.7 79.4-21 12.2-58.6 50.9-41.1 105.3C12.9 247.4 0 273.4 0 301c0 32.7 17.4 62.4 45.3 78.5-2.3 48.2 36.7 88.7 84.9 87.4 14.2 27.4 42.6 45 74.1 45 27.9 0 52.5-13.8 67.8-34.8 15.2 21 39.9 34.8 67.8 34.8 31.5 0 59.8-17.6 74-45 48.2 1.2 87.2-39.3 84.9-87.4 27.9-16.2 45.3-45.8 45.3-78.5-.1-27.6-13-53.6-34.5-70.7zM255.9 428.5c0 28.4-23.2 51.5-51.7 51.5-31.9 0-44.2-21.8-53.1-48.2-16.3 2.7-18.3 3.2-22.2 3.2-28.5 0-51.7-23.1-51.7-51.5 0-6.2.9-9.1 3.7-23C55.5 350 32.1 336.3 32.1 301c0-33.9 23-46.9 42.9-58.3-12.6-25.7-12.8-29.2-12.8-39.2 0-33.9 21.9-48.5 49.4-59.7-2.5-10.9-4.2-16.6-4.2-22.8 0-24.1 19.6-43.8 45.5-43.8h.4l12.7.3c5.9-22.9 15-45.5 45.8-45.5 24.3 0 44.2 19.7 44.2 44v352.5zm207.1-68c2.8 13.8 3.7 16.8 3.7 23 0 28.4-23.2 51.5-51.7 51.5-3.9 0-5.9-.5-22.2-3.2-8.9 26.4-21.2 48.2-53.1 48.2-28.5 0-51.7-23.1-51.7-51.5V76c0-24.3 19.8-44 44.2-44 30.7 0 39.9 22.5 45.8 45.5l12.7-.3h.4c25.9 0 45.5 19.7 45.5 43.8 0 6.2-1.7 11.9-4.2 22.8 27.5 11.2 49.4 25.8 49.4 59.7 0 10-.2 13.4-12.8 39.2 19.9 11.4 42.9 24.5 42.9 58.3 0 35.3-23.4 49-48.9 59.5z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faBrain = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;