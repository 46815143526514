import { IQuote } from "@/store/modules/quote/types";
import { differenceInDays, startOfDay } from "date-fns";
import { get, isString } from "lodash";
import { getSystemFilesURL } from ".";
export function capitalizeFirstLetter(string: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export function valueIsNotNull(value: any): boolean {
  const isNotNil = value !== null && value !== undefined;
  if (isNotNil) {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === "string") {
      return value.trim().length > 0;
    }

    return !!value;
  }
  return true;
}

export function christmasify(): boolean {
  const currentDate = new Date();
  const firstChristmasDay = new Date(currentDate.getFullYear(), 10, 24); // Month is 0-based, so 11 is December
  const twentySeventh = new Date(currentDate.getFullYear(), 11, 27);
  return currentDate >= firstChristmasDay && currentDate <= twentySeventh;
}

export function independenceLogo(): boolean {
  const currentDate = new Date();
  const firstIndependenceDay = new Date(currentDate.getFullYear(), 6, 1); // July 1st
  const lastDay = new Date(currentDate.getFullYear(), 6, 6); // July 6th
  return currentDate >= firstIndependenceDay && currentDate <= lastDay;
}

export function logoPath() {
  let logo = getSystemFilesURL("atlas-logo");
  if (christmasify()) {
    logo = getSystemFilesURL(`atlas-holiday-logo`);
  } else if (independenceLogo()) {
    logo = getSystemFilesURL(`atlas-logo-independence`);
  }
  return logo;
}

export function capitalizeFirstLetters(wordToCapitalize: string): string {
  if (!wordToCapitalize) return "";
  const words = wordToCapitalize.split(" ");

  return words
    .map(word => {
      return doToUpperCase(word[0]) + word.substring(1);
    })
    .join(" ");
}

export function doToUpperCase(word: string) {
  if (!isString(word)) {
    return word;
  }
  return word.toUpperCase();
}

export function padPolicyNumber(policyNumber: string): string {
  const policyNumberLength = 9;
  if (policyNumber) {
    const policyNumberString = policyNumber.toString().trim();
    if (policyNumberString.length < policyNumberLength) {
      policyNumber = policyNumberString.padStart(policyNumberLength, "0");
    }
  }
  return policyNumber;
}

export function formatAgencyCode(agencyCode: string): string {
  const notAvailableString = "N/A";
  if (agencyCode) {
    if (agencyCode.startsWith("AGA")) {
      const index = agencyCode.indexOf("-");
      return agencyCode.slice(index + 1) || notAvailableString;
    }
    return agencyCode;
  }
  return notAvailableString;
}

export function convertDashToSpace(value: string): string {
  return value.replace(/-/g, " ");
}

// Check if selected date is not more than a given number of days in the past or future
/**
 *
 * @param effectiveDate - the date we want to check
 * @returns number of days between effectiveDate and today (negative if effectiveDate is in the past)
 */
export const getNumberOfDays = (effectiveDate: string | number | Date) => {
  return differenceInDays(
    effectiveDate instanceof Date ? effectiveDate : new Date(effectiveDate),
    startOfDay(new Date())
  );
};

export function allowToPath(path: string): boolean {
  const allowedPaths = [
    /login/,
    /profile/,
    /notifications/,
    /not-authorized/,
    /emergency-maintenance/,
    /403/
  ];
  return allowedPaths.some((pathRegexp: RegExp) => pathRegexp.test(path));
}

export function getActiveSite(
  companyPrefix?: string,
  agentCode?: string
): string {
  let site = "ATLAS";
  //TOODO: Review this
  if (agentCode && agentCode.includes("CLL")) {
    site = "COLONIAL";
  } else if (companyPrefix) {
    companyPrefix = companyPrefix.trim();
    site = companyPrefix.startsWith("C") ? "COLONIAL" : site;
  }
  return site;
}

function removeSpaces(word: string): string {
  return word.replace(/\s/g, "");
}

export function stripAgencyCode(agencyCode: string) {
  agencyCode = agencyCode || "";
  agencyCode = agencyCode ? removeSpaces(agencyCode) : agencyCode;

  if (
    agencyCode.toUpperCase().startsWith("AGA-") ||
    agencyCode.toUpperCase().startsWith("CLL-")
  ) {
    const splitAgencyCode = agencyCode.split("-");
    agencyCode = splitAgencyCode.slice(1).join("-");
  }
  return agencyCode;
}

export function isFRMKValidCreditAndLossInfo(quote: IQuote) {
  if (quote && !Object.keys(quote).length) return false;
  const agentCode = get(quote, "agentCode", "");
  if (stripAgencyCode(agentCode).toLowerCase() === "frmk") {
    const { newConstruction = "" } = quote;
    if (newConstruction.trim().toLowerCase() == "yes") {
      return true;
    }
  }
  return false;
}

export function allowSAFEUPCR(quote: IQuote): boolean {
  const agentCode = get(quote, "agentCode", "");
  return stripAgencyCode(agentCode).toUpperCase() === "SAFE-UPCR";
}

export function setCookie(
  cName: string,
  value: string | boolean,
  exDays: number | null
): void {
  const exDate = new Date();
  const { VUE_APP_API_HTTP_SERVER = "" } = process.env;
  if (exDays !== null) {
    exDate.setDate(exDate.getDate() + exDays);
  }
  let domain = `domain=.atlasgeneral.com;`;
  const c_value =
    encodeURIComponent(value) +
    (exDays === null ? "" : "; expires=" + exDate.toUTCString());
  if (VUE_APP_API_HTTP_SERVER.includes("localhost")) {
    domain = "";
  }
  document.cookie = `${cName}=${c_value};${domain};path=/;SameSite=Lax;`;
}
