import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class EndorsementsService extends BaseCollectionService {
  constructor() {
    super("endorsements");
  }

  public async getEndorsements(query: {
    appliesTo: string;
    companyNumber?: number;
  }): Promise<ResponseWrapper> {
    const path = `${this.url}`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }

  public async deleteBulk(endorsementIds: string[]): Promise<ResponseWrapper> {
    const path = `endorsements/bulk-delete`;
    return await this.request(
      {
        method: "put",
        url: path,
        data: { endorsementIds }
      },
      { useToken: "access" }
    );
  }
}

export default new EndorsementsService();
