import {
  IPolicyRequest,
  IVerifyInsuredInfo
} from "@/store/modules/quickpay/types";
import BaseCollectionService from "./base.collection.service";

class QuickPayService extends BaseCollectionService {
  constructor() {
    super("storage-forms/policy-info-from-fiserv");
  }

  async getPolicyInfo(policyRequestData: IPolicyRequest) {
    const path = this.url;
    return await this.request({
      method: "post",
      url: path,
      data: policyRequestData
    });
  }
  async verifyInsuredInfo(payload: IVerifyInsuredInfo) {
    const path = "/policy-info-from-fiserv";
    return await this.request({
      method: "post",
      url: path,
      data: payload
    });
  }

  async getQuickPayPolicyInfo(policyRequestData: IPolicyRequest) {
    const path = "storage-forms/policy-info-from-fiserv-oneinc";
    return await this.request({
      method: "post",
      url: path,
      data: policyRequestData
    });
  }

  async getSessionId(site: "ATLAS" | "COLONIAL" = "ATLAS"): Promise<any> {
    const path = `payments/sessionId?site=${site}`;
    return await this.request({
      method: "get",
      url: path
    });
  }

  async getCompaniesPrefixes(query?: Record<string, any>) {
    const path = `companies/prefixes`;
    return await this.request({
      method: "get",
      url: path,
      params: query
    });
  }

  async doSavePolicySignature(data: any) {
    const path = `save-signed-policy`;
    return await this.request({
      method: "post",
      url: path,
      data
    });
  }
  async doReadPolicyPDF(payload: any) {
    const path = `save-signed-policy`;
    const response = await this.request({
      responseType: "blob",
      method: "post",
      url: path,
      data: { ...payload }
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `atlas-doc.pdf`);
    document.body.appendChild(link);
    link.click();
    return response;
  }
}

export default new QuickPayService();
