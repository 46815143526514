<template>
  <li
    @mouseenter="menuItemMouseEnter"
    @mouseleave="menuItemMouseLeave"
    :data-menu-item="index"
    data-menu-loc="5"
    class="menu-item menu-item-l1 has-submenu"
    :class="{
      [menuItemClassName(text)]: true,
      'force-hide-active-submenu': forceHideAllItems,
      'show-hover-helpers': showHoverHelpers
    }"
    :id="`mainMenuItem_${index}`"
    @click="doAction"
  >
    <span
      @click="$emit('toggleSubMenu', index)"
      v-if="subLinks && subLinks.length"
      class="mobile-menu-toggle"
    ></span
    ><router-link :to="to" class="relative">
      <svg
        v-if="icon && icon === `message`"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="white"
      >
        <path
          fill-rule="evenodd"
          d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
          clip-rule="evenodd"
        />
      </svg>

      <span
        v-if="badgeNumber"
        class="bg-red-700 text-xs w-4 h-4 rounded-full flex items-center justify-center font-normal absolute right-2 top-2"
        >{{ badgeNumber }}</span
      >
      <span v-if="text" itemprop="name">{{ text }}</span>
    </router-link>
    <ul
      v-if="subLinks && subLinks.length"
      class="sub-menu"
      :ref="`subMenu_${index}`"
      :id="`subMenu_${index}`"
    >
      <li>
        <ul class="sub-menu-flex bt-ie-block">
          <li
            ref=""
            class="sub-menu-item"
            v-for="(subLink, subLinkIndex) in subLinks"
            :key="`menu-${index}-subLink-${subLinkIndex}`"
          >
            <router-link :to="subLink.to"
              ><span itemprop="name">{{ subLink.text }}</span></router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>

<script>
import Vue from "vue";

import { appMapState } from "@/store/modules/app";
import { authMapActions } from "@/store/modules/auth";

export default Vue.extend({
  name: "MenuItem",
  props: {
    to: {
      type: String,
      default: "",
      required: true
    },
    text: {
      type: String,
      default: "",
      required: true
    },
    icon: {
      type: String,
      default: "",
      required: true
    },
    description: {
      type: String,
      default: "",
      required: false
    },
    showNotification: {
      type: Boolean,
      default: false,
      required: false
    },
    subLinks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    badgeNumber: {
      type: [Number, undefined],
      required: false,
      default: undefined
    },
    index: {
      type: Number,
      default: 0,
      required: true
    },
    action: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      itemHover: false,
      forceHideAllItems: false,
      showHoverHelpers: false
    };
  },
  methods: {
    ...authMapActions(["logout"]),
    menuItemMouseEnter() {
      this.itemHover = true;
      this.showHoverHelpers = true;
    },
    menuItemMouseLeave() {
      this.itemHover = false;
      this.forceHideAllItems = false;
      this.showHoverHelpers = false;
    },
    menuItemClassName(name) {
      const classNames = ["select-none"];
      classNames.push(`menu-item-${name.replace(/\s+/g, "-").toLowerCase()}`);
      if (this.showNotification) {
        classNames.push("has-notification");
      }
      return classNames.join(" ");
    },
    async doAction() {
      setTimeout(() => {
        this.forceHideAllItems = true;
        this.showHoverHelpers = false;
      }, 200);
      if (this.action && this.action === "doLogout") {
        await this.logout();
      }
    }
  },
  computed: {
    ...appMapState(["showMobileMenu"])
  }
});
</script>
