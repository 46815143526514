'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'folders';
var width = 640;
var height = 512;
var ligatures = [];
var unicode = 'f660';
var svgPathData = 'M592 64H400L345.37 9.37c-6-6-14.14-9.37-22.63-9.37H176c-26.51 0-48 21.49-48 48v80H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-80h80c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM480 464c0 8.84-7.16 16-16 16H48c-8.84 0-16-7.16-16-16V176c0-8.84 7.16-16 16-16h80v176c0 26.51 21.49 48 48 48h304v80zm128-128c0 8.84-7.16 16-16 16H176c-8.84 0-16-7.16-16-16V48c0-8.84 7.16-16 16-16h146.74l54.63 54.63c6 6 14.14 9.37 22.63 9.37h192c8.84 0 16 7.16 16 16v224z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faFolders = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;